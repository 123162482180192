import React, { Component } from 'react'

export class Conatctform extends Component {
  render() {
    return (
      <>
      <div class="contact-area sec-mar">
        <div className="container">
            <div className="row">
            <div className="col-md-5 col-xl-5">
				<div className="office-information">
					<h2>Contact Us</h2>
					<div className="single-information">
						<div className="bg-shape">
							<img src="assets/img/info-shape.png" alt=""/>
						</div>
						<div className="icon">
							<i><img src="assets/img/icons/location.png" alt=""/></i>
						</div>
						<div className="info-cnt">
							<h6>Location</h6>
							<p>
								Katy Ricebelt Basketball Officials Association Board
							</p>
						</div>
					</div>
					<div className="single-information">
						<div className="bg-shape">
							<img src="assets/img/info-shape.png" alt=""/>
						</div>
						<div className="icon">
							<i><img src="assets/img/icons/mobile.png" alt=""/></i>
						</div>
						<div className="info-cnt">
							<h6>Phone</h6>
							<a href="tel:12025550140">+1-234-456-7890</a>
						</div>
					</div>
					<div className="single-information">
						<div className="bg-shape">
							<img src="assets/img/info-shape.png" alt=""/>
						</div>
						<div className="icon">
							<i><img src="assets/img/icons/envelope.png" alt=""/></i>
						</div>
						<div className="info-cnt">
							<h6>Email</h6>
							<a href="/">ricebelt1@comcast.net </a>
						</div>
					</div>
				</div>
			</div>
            <div className="col-md-7 col-xl-7">
            <div className="contact-form">
            <h3>Write A Message</h3>
            <form action="#" method="post">
                <input type="text" name="name" placeholder="Your Full Name"/>
                <input type="email" name="email" placeholder="Your Email"/>
                <input type="text" name="phone" placeholder="Your Phone"/>
                <input type="text" name="subject" placeholder="Subject"/>
                <textarea name="message" cols="30" rows="10" placeholder="Write Message"></textarea>
                <div className="button--wrap">
                    <button type="submit" className="eg-btn btn--primary golf-btn mx-auto">
                        Submit Now <i className="bi bi-arrow-right"></i>
                    </button>
                </div>
            </form>
            </div>
            </div>
            </div>
        </div>
      </div>
      </>
    )
  }
}

export default Conatctform
