import React, { Component } from 'react'

export class Training extends Component {
  render() {
    return (
      <>
      <div className="field-review sec-mar">
			<div className="container">
				<div className="row">
					<div className="col-lg-7 col-xl-6 or2">
						<div className="review-content">
							<div className="title">
								<span>Training</span>
								<h2>Find the best Basketball Training & Field here.</h2>
							</div>
							<p>
								Sed vestibulum ex lectus, ac sollicitudin erat fringilla at. Donec vehicula sollici tudin,oi mi nec posuere scelerisque, sapien massa maximus ipsum,vel arcu. Ut rutrum elit nibh, sit amet aliquam.
							</p>
							<ul className="field-points">
								<li>
									<i className="far fa-check-circle"></i> Ejection Process
								</li>
								<li>
									<i className="far fa-check-circle"></i> Misunderstood Rules in High School Basketball
								</li>
								<li>
									<i className="far fa-check-circle"></i> NFHS Video Clips
								</li>
								<li>
									<i className="far fa-check-circle"></i> NFHS Communication Techniques
								</li>
								<li>
									<i className="far fa-check-circle"></i> NFHS Rules Changes, Comments, Points of Emphasis, provided by NFHS Basketball
								</li>
							</ul>
							<div className="button--wrap">
								<a href="/" className="eg-btn btn--primary golf-btn mx-auto">Learn More <i className="bi bi-arrow-right"></i></a>
							</div>
						</div>
					</div>
					<div className="col-lg-5 col-xl-6 or1">
						<div className="field-img">
							<img src="assets/img/field-review.png" alt=""/>
						</div>
					</div>
				</div>
			</div>
		</div>
      </>
    )
  }
}

export default Training
