import React, { Component } from 'react'
import Breadcrumb from '../Breadcrumb';
import AboutMid from './AboutMid';
export class Aboutus extends Component {
  render() {
    return (
      <>
      <Breadcrumb title="About Us"/>
      <AboutMid/>
      </>
    )
  }
}

export default Aboutus
