import React, { Component } from 'react'

export default class Ourchapter2 extends Component {
  render() {
    return (
     <>
     <div className="feature-area">
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-12 col-xl-6">
						<div className="title white text-center">
							<span>Our Chapter</span>
							<h2>This section contains forms to be used by Katy Ricebelt Chapter members.</h2>
						</div>
					</div>
				</div>
				<div className="feature">
					<div className="row g-4">
						<div className="col-md-6 col-lg-3">
							<div className="single-feature">
								<div className="icon">
									<i><img src="assets/img/01.png" alt=""/></i>
								</div>
								<b>Official's Invoice</b>
							</div>
						</div>
						<div className="col-md-6 col-lg-3">
							<div className="single-feature">
								<div className="icon">
									<i><img src="assets/img/02.png" alt=""/></i>
								</div>
								<b>Tournament Invoice </b>
							</div>
						</div>
						<div className="col-md-6 col-lg-3">
							<div className="single-feature">
								<div className="icon">
									<i><img src="assets/img/basketball-player.png" alt=""/></i>
								</div>
								<b>W-9 Form</b>
							</div>
						</div>
						<div className="col-md-6 col-lg-3">
							<div className="single-feature">
								<div className="icon">
									<i><img src="assets/img/03.png" alt=""/></i>
								</div>
								<b>Debarment Form</b>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
     </>
    )
  }
}
