import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes,Route } from "react-router-dom";
import './index.css';
import './css/swiper-bundle.min.css';
import './css/nice-select.css';
import './css/magnific-popup.css';
import './css/style.css';
import './css/all.min.css';
import './css/bootstrap-icons.css';
import './css/bootstrap.min.css';
import './css/fontawesome.min.css';
import './css/lightbox.min.css';
import reportWebVitals from './reportWebVitals';
import Header from './Header';

import Footer from './Footer';
import Home from './Component/Home/Home';
import Aboutus from './Component/About/Aboutus';
import Conatctus from './Component/Contact/Conatctus';
const scriptFile1 = document.createElement("script");
scriptFile1.src = "/assets/js/jquery-3.6.0.min.js";
document.body.appendChild(scriptFile1);

const scriptFile2 = document.createElement("script");
scriptFile2.src = "/assets/js/popper.min.js";
document.body.appendChild(scriptFile2);

const scriptFile3 = document.createElement("script");
scriptFile3.src = "/assets/js/bootstrap.min.js";
document.body.appendChild(scriptFile3);

const scriptFile4 = document.createElement("script");
scriptFile4.src = "/assets/js/swiper-bundle.min.js";
document.body.appendChild(scriptFile4);

const scriptFile5 = document.createElement("script");
scriptFile5.src = "/assets/js/jquery.lazy.js";
document.body.appendChild(scriptFile5);

const scriptFile6 = document.createElement("script");
scriptFile6.src = "/assets/js/jquery.nice-select.min.js";
document.body.appendChild(scriptFile6);

const scriptFile7 = document.createElement("script");
scriptFile7.src = "/assets/js/anime.min.js";
document.body.appendChild(scriptFile7);

const scriptFile8 = document.createElement("script");
scriptFile8.src = "/assets/js/waypoints.min.js";
document.body.appendChild(scriptFile8);

const scriptFile9 = document.createElement("script");
scriptFile9.src = "/assets/js/jquery.counterup.min.js";
document.body.appendChild(scriptFile9);

const scriptFile10 = document.createElement("script");
scriptFile10.src = "/assets/js/lightbox.min.js";
document.body.appendChild(scriptFile10);

const scriptFile11 = document.createElement("script");
scriptFile11.src = "/assets/js/jquery.magnific-popup.min.js";
document.body.appendChild(scriptFile11);

const scriptFile12 = document.createElement("script");
scriptFile12.src = "/assets/js/isotope.pkgd.min.js";
document.body.appendChild(scriptFile12);

const scriptFile13 = document.createElement("script");
scriptFile13.src = "/assets/js/masonry.pkgd.min.js";
document.body.appendChild(scriptFile13);

const scriptFile14 = document.createElement("script");
scriptFile14.src = "/assets/js/jquery.counterup.min.js";
document.body.appendChild(scriptFile14);

const scriptFile15 = document.createElement("script");
scriptFile15.src = "/assets/js/custom.js";
document.body.appendChild(scriptFile15);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
   <Header/>
   <Routes>
   <Route path="/" element={ <Home />} />
   <Route path="/about" element={ <Aboutus />} />
   <Route path="/conatctus" element={ <Conatctus />} />
   </Routes>
    <Footer/>
  </BrowserRouter>
  
  
);

reportWebVitals();
