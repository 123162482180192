import React, { Component } from 'react'

export default class Homeaboutsection extends Component {
  render() {
    return (
      <>
      <div className="about-area sec-mar">
			<div className="container">
				<div className="row">
					<div className="col-lg-6 col-xl-6">
						<div className="about-left">
							<div className="title">
								<span>About Us</span>
								<h2>The organization is dedicated to providing highly professional</h2>
							</div>
							<p></p>
							<p>
								and well trained officials for the schools we service from Jr. High through Varsity levels.  Each night RiceBelt officials work two or three games.  During a typical game an official will put in approximately 2 miles/game by the end of the night.
							</p>
							<div className="button--wrap">
								<a href="/about" className="eg-btn btn--primary golf-btn mx-auto">About More <i className="bi bi-arrow-right"></i></a>
							</div>
						</div>
					</div>
					<div className="col-lg-6 col-xl-6">
						<div className="about-right">
							<div className="right-top">
								<img src="assets/img/basketball2.jpg" alt=""/>
							</div>
							<div className="right-video-play">
								<img src="assets/img/basketball2.jpg" alt=""/>
								<div className="right-play-inner">
									<a className="popup-video" href="https://www.youtube.com/watch?v=0O2aH4XLbto"><i className="fas fa-play"></i></a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
      </>
    )
  }
}
