import React, { Component } from 'react'

export default class Ourchapter extends Component {
  render() {
    return (
      <>
      <div className="our-club sec-mar-bottom">
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-xl-8">
						<div className="title text-center">
							<span>Our Chapter </span>
							<h2>This section contains documents to be used by Katy Ricebelt Chapter members.</h2>
						</div>
					</div>
				</div>
				<div className="member-facilities white">
					<div className="row justify-content-around">
						<div className="col-auto">
							<div className="member-facility">
								<div className="icon">
									<img src="assets/img/14.png" alt=""/>
								</div>
								<a href="/">Rice Belt Basketball Chapter Official's Invoice </a>
							</div>
						</div>
						<div className="col-auto">
							<div className="member-facility">
								<div className="icon">
									<img src="assets/img/1.png" alt=""/>
								</div>
								<a href="/">Constitution and By-Laws</a>
							</div>
						</div>
						<div className="col-auto">
							<div className="member-facility">
								<div className="icon">
									<img src="assets/img/2.png" alt=""/>
								</div>
								<a href="/">Requirements to be in Good Standing</a>
							</div>
						</div>
						<div className="col-auto">
							<div className="member-facility">
								<div className="icon">
									<img src="assets/img/4.png" alt=""/>
								</div>
								<a href="/">Schools Serviced</a>
							</div>
						</div>
						<div className="col-auto">
							<div className="member-facility">
								<div className="icon">
									<img src="assets/img/7.png" alt=""/>
								</div>
								<a href="/">Game Acceptance and Cancellation Policy </a>
							</div>
						</div>
						<div className="col-auto">
							<div className="member-facility">
								<div className="icon">
									<img src="assets/img/6.png" alt=""/>
								</div>
								<a href="/">Misapplication of Rules Consequence's</a>
							</div>
						</div>
						<div className="col-auto">
							<div className="member-facility">
								<div className="icon">
									<img src="assets/img/8.png" alt=""/>
								</div>
								<a href="/">TASO Game Assignments: Conflicts of Interest Policy</a>
							</div>
						</div>
						<div className="col-auto">
							<div className="member-facility">
								<div className="icon">
									<img src="assets/img/9.png" alt=""/>
								</div>
								<a href="/">Uniform Requirements</a>
							</div>
						</div>
						<div className="col-auto">
							<div className="member-facility">
								<div className="icon">
									<img src="assets/img/5.png" alt=""/>
								</div>
								<a href="/">Mileage Guide Lines for Officials</a>
							</div>
						</div>
						<div className="col-auto">
							<div className="member-facility">
								<div className="icon">
									<img src="assets/img/12.png" alt=""/>
								</div>
								<a href="/">Playoff Requirements</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
      </>
    )
  }
}
