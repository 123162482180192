import React, { Component } from 'react'
import { Link } from "react-router-dom";
export default class Header extends Component {
  render() {
    return (
      <>
      <header className="position_top">
			<div className="container-fluid">
				<div className="row align-items-center">
					<div className="col-6 col-md-6 col-lg-2">
						<div className="logo">
							<a href="index.html"><img src="assets/img/logo.png" alt="logo.png"/></a>
						</div>
					</div>
					<div className="col-6 col-md-6 col-lg-10 text-end">
						<nav className="main-nav">
							<div className="mobile-menu-logo">
								<a href="/"><img src="assets/img/logo.png" alt="logo.png" /></a>
							</div>
							<ul>
								<li className="active">
								<a href="/">Home</a>
								</li>
                                <li>
								<Link to="/about">About</Link>
									
								</li>
								<li>
								<Link to="/conatctus">Contact Us</Link>
									
								</li>
								<li>
									<a href="/">Training</a>
								</li>
								<li>
									<a href="/">Coaches</a>
								</li>
								<li>
									<a href="/">Services</a>
								</li>
								<li>
									<a href="/">Photo Gallery</a>
								</li>
								
							</ul>
							<div className="hot-line">
								<div className="icon">
									<i><img src="assets/img/icons/phone-white.svg" alt="phone-white.svg" /></i>
								</div>
								<div className="line-cnt">
									<span>Hot Line Number</span>
									<a href="tel:12025550140">+1-234-456-7890</a>
								</div>
							</div>
							<div className="btn-block">
								<div className="membership-btn">
									<a href="/">Join Membership<i className="far fa-user"></i></a>
								</div>
							</div>
						</nav>
						<div className="mobile-menu">
							<a href="/" className="cross-btn"> <span className="cross-top"></span> <span className="cross-middle"></span> <span className="cross-bottom"></span> </a>
						</div>
					</div>
				</div>
			</div>
		</header>
      </>
    )
  }
}
