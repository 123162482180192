import React, { Component } from 'react'
import Slider from './Slider';
import Homeaboutsection from './Homeaboutsection';
import Ourchapter from './Ourchapter';
import Ourchapter2 from './Ourchapter2';
import Training from './Training';
import Gallery from './Gallery';
export class Home extends Component {
  render() {
    return (
        <>
        <Slider/>
        <Homeaboutsection/>
        <Ourchapter/>
        <Ourchapter2/>
        <Training/>
        <Gallery/>
        </>
    )
  }
}

export default Home
