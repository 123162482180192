import React, { Component } from 'react'
import Breadcrumb from '../Breadcrumb';
import Conatctform from './Conatctform';
export class Conatctus extends Component {
  render() {
    return (
     <>
     <Breadcrumb title="Contact Us"/>
     <Conatctform/>
     </>
    )
  }
}

export default Conatctus
