import React, { Component } from 'react'

export class Gallery extends Component {
  render() {
    return (
     <>
     <div className="gallery-area sec-mar ">
			<div className="container">
				<div className="row">
					<div className="col-12">
						<div className="title text-center">
							<span>Our Photo Gallery </span>
							<h2>Lorem ipsum dolor sit ametadipiscing elit</h2>
						</div>
						<div className="gallery-tab">
							<ul className="tab-menu">
								<li className="active" data-filter="*">
									All
								</li>
								<li data-filter=".tournament">
									Chapter
								</li>
								<li data-filter=".course">
									Training
								</li>
								<li data-filter=".management">
									Coaches
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div className="row g-2 gallery-grid">
					<div className="col-md-3 gallery-item management">
						<div className="single-gallery">
							<a data-lightbox="image1" href="assets/img/gallery/gallery-5-big.png"> <img src="assets/img/gallery/gallery-5.jpg" className="lazy" alt=""/> </a>
						</div>
					</div>
					<div className="col-md-3 gallery-item course">
						<div className="single-gallery">
							<a data-lightbox="image1" href="assets/img/gallery/gallery-6-big.png"> <img src="assets/img/gallery/gallery-6.jpg" className="lazy" alt=""/> </a>
						</div>
					</div>
					<div className="col-md-3 gallery-item management">
						<div className="single-gallery">
							<a data-lightbox="image1" href="assets/img/gallery/gallery-3-big.png"> <img src="assets/img/gallery/gallery-7.jpg" className="lazy" alt=""/> </a>
						</div>
					</div>
					<div className="col-md-3 gallery-item tournament">
						<div className="single-gallery">
							<a data-lightbox="image1" href="assets/img/gallery/gallery-4-big.png"> <img src="assets/img/gallery/gallery-51.jpg" className="lazy" alt=""/> </a>
						</div>
					</div>
					<div className="col-md-6 gallery-item course">
						<div className="single-gallery">
							<a data-lightbox="image1" href="assets/img/gallery/gallery-2-big.png"> <img src="assets/img/gallery/gallery-2.jpg" className="lazy" alt=""/> </a>
						</div>
					</div>
					<div className="col-md-3 gallery-item tournament">
						<div className="single-gallery">
							<a data-lightbox="image1" href="assets/img/gallery/gallery-7-big.png"> <img src="assets/img/gallery/gallery-50.jpg" className="lazy" alt=""/> </a>
						</div>
					</div>
					<div className="col-md-3 gallery-item management">
						<div className="single-gallery">
							<a data-lightbox="image1" href="assets/img/gallery/gallery-8-big.png"> <img src="assets/img/gallery/gallery-55.jpg" className="lazy" alt=""/> </a>
						</div>
					</div>
				</div>
				<div className="load-more">
					<div className="button--wrap">
						<a className="eg-btn btn--primary golf-btn mx-auto" href="/">Load More <i className="bi bi-arrow-right"></i></a>
					</div>
				</div>
			</div>
		</div>
     </>
    )
  }
}

export default Gallery
