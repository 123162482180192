import React, { Component } from 'react'

export default class Slider extends Component {
  render() {
    return (
      <>
      <div className="hero-area">
			<div className="swiper hero-slider">
				<div className="swiper-wrapper">
					<div className="swiper-slide">
						<div className="hero-single bg-1">
							<div className="container">
								<div className="row">
									<div className="col-lg-8 col-xl-6">
										<div className="hero-wrapper">
											<div className="hero-content">
												<h2>Welcome to Katy Ricebelt Officials Association</h2>
												<p>
													The Katy RiceBelt Chapter is made up of over 100+ officials and is a member of the Texas Association of Sports Officials (TASO).
												</p>
												<div className="button--wrap mt-30">
													<a href="/" className="eg-btn btn--primary golf-btn mx-auto btn-w">Read More <i className="bi bi-arrow-right"></i></a>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="swiper-slide">
						<div className="hero-single bg-2">
							<div className="container">
								<div className="row">
									<div className="col-lg-8 col-xl-6">
										<div className="hero-wrapper">
											<div className="hero-content">
												<h2>Welcome to Katy Ricebelt Officials Association</h2>
												<p>
													The Katy RiceBelt Chapter is made up of over 100+ officials and is a member of the Texas Association of Sports Officials (TASO).
												</p>
												<div className="button--wrap mt-30">
													<a href="/" className="eg-btn btn--primary golf-btn mx-auto btn-w">Read More <i className="bi bi-arrow-right"></i></a>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="swiper-pagination"></div>
				<div className="swiper-scrollbar"></div>
			</div>
			<div className="slider-paginate">
				<div className="swiper-pagination-c"></div>
				<div className="swiper-scrollbar-c"></div>
			</div>
		</div>
      </>
    )
  }
}
