import React, { Component } from 'react'

export class Breadcrumb extends Component {
  render() {
    let { title }=this.props;
    return (
      <>
      <div className="breadcrumb">
			<div className="container">
				<div className="row">
					<div className="col-12">
						<div className="breadcrumb-wrapper">
							<div className="breadcrumb-inner">
								<h1>{title}</h1>
								<span><a href="/">Home<i className="fas fa-angle-right"></i></a>{title}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
      </>
    )
  }
}

export default Breadcrumb
