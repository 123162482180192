import React, { Component } from 'react'

export class Footer extends Component {
  render() {
    return (
      <>
      <footer>
			<div className="container">
				<div className="footer-top">
					<div className="row justify-content-center">
						<div className="col-lg-10 col-xl-8">
							<div className="footer-logo">
								<a href="index.html"><img src="assets/img/logo.png" alt=""/></a>
								<p>
									The Katy RiceBelt Chapter is made up of over 100+ officials and is a member of the Texas Association of Sports Officials (TASO).
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className="footer-middle">
					<div className="row gy-5">
						<div className="col-md-6 col-lg-3">
							<div className="footer-widget two">
								<div className="social-media">
									<h4>Find Here:</h4>
									<ul className="social-icons">
										<li>
											<a href="https://www.instagram.com/"><i className="fab fa-instagram"></i></a>
										</li>
										<li>
											<a href="https://www.pinterest.com/"><i className="fab fa-pinterest"></i></a>
										</li>
										<li>
											<a href="https://www.twitter.com/"><i className="fab fa-twitter"></i></a>
										</li>
										<li>
											<a href="https://www.facebook.com/"><i className="fab fa-facebook-f"></i></a>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="col-md-6 col-lg-3">
							<div className="footer-widget two">
								<h4>Quick Links</h4>
								<ul className="footer-menu">
									<li>
										<a href="/">Welcome Letter</a>
									</li>
									<li>
										<a href="/">Calendar / Meetings</a>
									</li>
									<li>
										<a href="/">TASO</a>
									</li>
									<li>
										<a href="/">NFHS</a>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-md-6 col-lg-3">
							<div className="footer-widget two">
								<h4>Hours of Open</h4>
								<ul className="footer-menu">
									<li>
										<a href="/">New Member Information</a>
									</li>
									<li>
										<a href="/">Arbiter</a>
									</li>
									<li>
										<a href="/">UIL</a>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-md-6 col-lg-3">
							<div className="footer-widget two">
								<h4>Address</h4>
								<div className="number">
									<div className="num-icon">
										<i className="fas fa-phone-alt"></i>
									</div>
									<div className="phone">
										<a href="tel:05661111985">+1 123 456 7890</a>
									</div>
								</div>
								<div className="office-mail">
									<div className="mail-icon">
										<i className="far fa-envelope"></i>
									</div>
									<div className="email">
										<a href="tell:ricebelt1@comcast.net">ricebelt1@comcast.net</a>
									</div>
								</div>
								<div className="address">
									<div className="address-icon">
										<i className="fas fa-map-marker-alt"></i>
									</div>
									<p>
										168/170, Avenue 01, ASRFT, XYZ
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="footer-bottom">
					<div className="row align-items-center">
						<div className="col-12 ">
							<div className="copy-txt two text-center">
								<span>Copyright © 2014-2022, Katy Ricebelt Officials Associations. All rights reserved.</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</footer>
      </>
    )
  }
}

export default Footer
